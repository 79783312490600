import { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Link as ChakraLink,
  List,
  ListItem,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import { Response } from "interfaces/api/profile/index";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Index = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await Fetch.get(`my`).then((res) => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
      });
    })();
  }, []);

  return (
    <Layout
      page="サイトマップ"
      my={response?.my}
      loading={loading}
    >
      <>
        <Card
          w="full"
          mt={2}
        >
          <CardBody>
            <VStack
              spacing={3}
              alignItems="flex-start"
              w="full"
            >
              <HStack
                w="full"
                onClick={() => navigate("/welcome")}
                h={10}
              >
                <Text>ホーム</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                w="full"
                onClick={() => navigate("/exam")}
                h={10}
              >
                <Text>チェックシート</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                w="full"
                onClick={() => navigate("/profile")}
                h={10}
              >
                <Text>個人情報</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              {(response?.my?.status === 2 ||
                response?.my?.status === 3 ||
                response?.my?.status === 4) && (
                <>
                  <HStack
                    w="full"
                    onClick={() => navigate("/licenses")}
                    h={10}
                  >
                    <Text>資格更新</Text>
                    <Spacer flexGrow={1} />
                    <FiChevronRight />
                  </HStack>

                  <Divider />
                </>
              )}

              <HStack
                w="full"
                onClick={() => navigate("/e-commerce")}
                h={10}
              >
                <Text>各種通販</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                w="full"
                onClick={() => navigate("/payments")}
                h={10}
              >
                <Text>決済履歴</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                w="full"
                onClick={() => navigate("/downloads")}
                h={10}
              >
                <Text>各種ダウンロード</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                w="full"
                onClick={() => navigate("/seminars")}
                h={10}
              >
                <Text>オンライン・セミナー</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                w="full"
                as={ChakraLink}
                href="https://souzokushindan.com/"
                isExternal={true}
                h={10}
              >
                <Text>相続診断協会</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                w="full"
                as={ChakraLink}
                href="/law"
                isExternal={true}
                h={10}
              >
                <Text>特定商取引法に基づく表示</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                w="full"
                as={ChakraLink}
                href="https://souzokushindan.com/privacy.html"
                isExternal={true}
                h={10}
              >
                <Text>プライバシーポリシー</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </>
    </Layout>
  );
};
