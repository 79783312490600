import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";
import { MEMBER_TOKEN_LOCAL_KEY } from "config/const";
import { useFormik } from "formik";
import { Request, Response } from "interfaces/api/login/index";
import { Fetch } from "utils/fetch";
import { LocalStorage } from "utils/local_stoage";
import { useToast } from "utils/toast";
import { LayoutNonMember } from "views/_layout_nonmember";
import * as yup from "yup";

const validationSchemas = yup.object({
  account: yup.number().required("認定番号を入力してください"),
  password: yup
    .string()
    .required("パスワードを入力してください")
    .min(4, "パスワードを正しく入力してください")
    .max(20, "パスワードを正しく入力してください")
});

export const Index = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(
    undefined
  );
  const { sendToast } = useToast();

  const formik = useFormik({
    initialValues: {
      account: undefined,
      password: ""
    } as Request,
    validationSchema: validationSchemas,
    onSubmit: (values: Request, { setSubmitting }) => {
      Fetch.post("login", values).then((res) => {
        setSubmitting(false);

        if (res?.error || !res.token || res.token === "") {
          sendToast({
            title: "ログイン失敗しました",
            description: "相続診断士認定番号もしくはパスワードが違います。",
            status: "error"
          });
          return false;
        }

        LocalStorage.set(MEMBER_TOKEN_LOCAL_KEY, res.token);
        navigate(`/welcome`);
        return false;
      });
    }
  });

  return (
    <LayoutNonMember>
      <>
        <Center
          w="full"
          h={20}
          bg="green.800"
          color="white"
        >
          <Text
            fontSize="xl"
            p={3}
          >
            こちらのページは「相続診断士」専用ページとなっております。
          </Text>
        </Center>

        <HStack
          w="full"
          mt={5}
        >
          <Text color="green.800">
            ご自身の認定カードまたは認定証をご用意ください。
          </Text>
        </HStack>

        <VStack
          w="full"
          spacing={5}
          borderColor="green.800"
          borderWidth={2}
          rounded="md"
          p={5}
          mt={3}
        >
          <FormControl
            mt={5}
            isRequired
            isInvalid={formik.touched.account && Boolean(formik.errors.account)}
          >
            <Input
              id="account"
              name="account"
              type="number"
              placeholder="認定番号を入力してください"
              isRequired
              onChange={formik.handleChange}
              value={formik.values.account}
              maxLength={20}
              size="lg"
            />
            <FormErrorMessage>{String(formik.errors.account)}</FormErrorMessage>
          </FormControl>

          <FormControl
            mt={5}
            isRequired
            isInvalid={
              formik.touched.password && Boolean(formik.errors.password)
            }
          >
            <Input
              id="password"
              name="password"
              type="password"
              placeholder="パスワードを入力してください"
              isRequired
              onChange={formik.handleChange}
              value={formik.values.password}
              maxLength={20}
              size="lg"
            />
            <FormErrorMessage>
              {String(formik.errors.password)}
            </FormErrorMessage>
          </FormControl>

          <Button
            type="submit"
            my={5}
            w="full"
            bg="green.800"
            color="white"
            size="lg"
            colorScheme="base"
            onClick={() => formik.handleSubmit()}
            isLoading={formik.isSubmitting}
            loadingText="ログイン中..."
          >
            ログイン
          </Button>
        </VStack>

        <HStack
          w="full"
          mt={5}
        >
          <Text color="green.800">
            認定番号・パスワードが解らない方は相続診断協会事務局へご連絡ください。
          </Text>
        </HStack>

        <VStack
          w="full"
          spacing={5}
          borderColor="green.800"
          borderWidth={2}
          rounded="md"
          p={5}
          mt={3}
          alignItems="flex-start"
        >
          <Text>次の対応ブラウザのいずれかをご利用下さい。</Text>
          <Text>
            Google Chrome 最新版 (Windows、Mac、Android
            タブレット、スマートフォン) ダウンロードは
            <Link
              href="https://www.google.com/chrome/"
              isExternal={true}
              color="green.800"
            >
              こちら
            </Link>
          </Text>
          <Text>
            Microsoft Edge 最新版 ダウンロードは
            <Link
              href="https://www.microsoft.com/ja-jp/edge/download"
              isExternal={true}
              color="green.800"
            >
              こちら
            </Link>
          </Text>
          <Text>インターネット回線の安定した場所でご利用ください。</Text>
          <Text mt={5}>
            ご注意:会社などのパソコンで更新を行う際に、各会社のパソコンセキュリティによってはエラーが起こります。その際は個人のパソコンもしくはその他の機材で更新手続きを行ってください。
          </Text>
        </VStack>
      </>
    </LayoutNonMember>
  );
};
