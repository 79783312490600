import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Link,
  Show,
  SkeletonText,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import { Response } from "interfaces/api/profile/index";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Index = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await Fetch.get(`my`).then((res) => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
      });
    })();
  }, []);

  return (
    <Layout
      page="個人情報"
      my={response?.my}
      loading={loading}
    >
      <>
        <Show above="md">
          <Breadcrumb>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">ホーム</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Show>

        <Card
          w="full"
          mt={3}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">基本情報</Heading>
          </CardHeader>
          <CardBody>
            {loading && (
              <SkeletonText
                noOfLines={10}
                spacing={5}
                skeletonHeight={5}
              />
            )}
            {response && response.my && (
              <VStack
                w="full"
                spacing={3}
              >
                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.id}</Text>
                    <Text as="sub">認定番号</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.name || "-"}</Text>
                    <Text as="sub">お名前</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.kana || "-"}</Text>
                    <Text as="sub">フリガナ</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.email || "-"}</Text>
                    <Text as="sub">メールアドレス</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.approvalAt || "-"}</Text>
                    <Text as="sub">認定日</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.approvalPeriod || "-"}</Text>
                    <Text as="sub">認定期間</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.company || "-"}</Text>
                    <Text as="sub">会社名</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.department || "-"}</Text>
                    <Text as="sub">部署名</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.position || "-"}</Text>
                    <Text as="sub">役職</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.addressType || "-"}</Text>
                    <Text as="sub">住所区分</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.zip || "-"}</Text>
                    <Text as="sub">郵便番号</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.pref || "-"}</Text>
                    <Text as="sub">都道府県</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.address1 || "-"}</Text>
                    <Text as="sub">住所 市区町村</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.address2 || "-"}</Text>
                    <Text as="sub">住所 町名番地</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.address3 || "-"}</Text>
                    <Text as="sub">住所 建物名</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.tel || "-"}</Text>
                    <Text as="sub">電話番号</Text>
                  </VStack>
                </HStack>

                <Divider my={3} />

                <HStack w="full">
                  <VStack alignItems="flex-start">
                    <Text>{response.my.birth || "-"}</Text>
                    <Text as="sub">生年月日</Text>
                  </VStack>
                </HStack>
              </VStack>
            )}
          </CardBody>
        </Card>
      </>
    </Layout>
  );
};
