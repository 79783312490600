import * as React from "react";
import {
  FiCast,
  FiCheckSquare,
  FiCreditCard,
  FiDownloadCloud,
  FiHome,
  FiLogOut,
  FiMenu,
  FiMoreHorizontal,
  FiPackage,
  FiPower,
  FiRotateCw,
  FiSettings,
  FiShield,
  FiShoppingCart,
  FiTerminal
} from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  Divider,
  Heading,
  Hide,
  HStack,
  IconButton,
  Image,
  Input,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Show,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import { MEMBER_TOKEN_LOCAL_KEY } from "config/const";
import { Member } from "interfaces/models/member";
import { LocalStorage } from "utils/local_stoage";

interface Props {
  children?: React.ReactElement;
  loading?: boolean;
  page?: string;
  my?: Member;
}

export const LayoutMember: React.FunctionComponent<Props> = ({
  children,
  loading = true,
  page,
  my
}: Props) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    LocalStorage.set(MEMBER_TOKEN_LOCAL_KEY, "");
    navigate("/login");
  };

  return (
    <VStack
      w="full"
      h="100vh"
      spacing={0}
      alignItems="center"
    >
      <VStack
        w="full"
        h="full"
        maxW={1024}
        alignItems="flex-start"
        px={3}
        spacing={0}
      >
        <HStack
          w="full"
          // justifyContent="space-between"
        >
          <Show above="md">
            <Image
              src="/static/images/logo/logo_jida_pc.png"
              alt="logo"
              h={50}
            />

            <Spacer flexGrow={1} />

            <Image
              src="/static/images/header_tel.png"
              alt="tel"
              h={50}
            />
          </Show>
          <Hide above="md">
            <Image
              src="/static/images/logo/logo.png"
              alt="logo"
              h={50}
            />
            <Heading
              size="lg"
              ml={3}
            >
              {page}
            </Heading>

            <Spacer flexGrow={1} />

            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMenu />}
                variant="outline"
              />
              <MenuList minW={200}>
                {!loading && !!my && (
                  <>
                    <MenuItem>
                      <Text>{my?.name || "-"} 様</Text>
                    </MenuItem>
                    <MenuItem>
                      <Text>有効期限 {my?.approvalPeriod}</Text>
                    </MenuItem>

                    <MenuDivider />

                    <MenuItem
                      icon={<FiHome size={16} />}
                      as={Link}
                      to="/welcome"
                    >
                      <Text mt={1.5}>ホーム</Text>
                    </MenuItem>

                    {(my?.status === 1 || my?.status === 2) && (
                      <MenuItem
                        icon={<FiCheckSquare size={16} />}
                        as={Link}
                        to="/exam"
                      >
                        <Text mt={1.5}>チェックシート</Text>
                      </MenuItem>
                    )}

                    <MenuItem
                      icon={<FiSettings size={16} />}
                      as={Link}
                      to="/profile"
                    >
                      <Text mt={1.5}>個人情報</Text>
                    </MenuItem>

                    {(my?.status === 2 ||
                      my?.status === 3 ||
                      my?.status === 4) && (
                      <MenuItem
                        icon={<FiRotateCw size={16} />}
                        as={Link}
                        to="/licenses"
                      >
                        <Text mt={1.5}>資格更新</Text>
                      </MenuItem>
                    )}

                    <MenuItem
                      icon={<FiShoppingCart size={16} />}
                      as={Link}
                      to="/e-commerce"
                    >
                      <Text mt={1.5}>各種通販</Text>
                    </MenuItem>
                    <MenuItem
                      icon={<FiCreditCard size={16} />}
                      as={Link}
                      to="/payments"
                    >
                      <Text mt={1.5}>決済履歴</Text>
                    </MenuItem>

                    {(my?.status === 1 || my?.status === 2) && (
                      <MenuItem
                        icon={<FiDownloadCloud size={16} />}
                        as={Link}
                        to="/downloads"
                      >
                        <Text mt={1.5}>各種ダウンロード</Text>
                      </MenuItem>
                    )}

                    <MenuItem
                      icon={<FiCast size={16} />}
                      as={Link}
                      to="/seminars"
                    >
                      <Text mt={1.5}>オンライン・セミナー</Text>
                    </MenuItem>

                    {my?.status === 5 && (
                      <MenuItem
                        icon={<FiTerminal size={16} />}
                        as={Link}
                        to="/others"
                      >
                        <Text mt={1.5}>その他</Text>
                      </MenuItem>
                    )}

                    <MenuDivider />

                    <MenuItem
                      icon={<FiPower size={16} />}
                      as={ChakraLink}
                      href="https://souzokushindan.com/"
                      isExternal={true}
                    >
                      <Text mt={1.5}>相続診断協会</Text>
                    </MenuItem>
                    <MenuItem
                      icon={<FiPackage size={16} />}
                      as={ChakraLink}
                      href="/law"
                      isExternal={true}
                    >
                      <Text mt={1.5}>特定商取引法に基づく表示</Text>
                    </MenuItem>
                    <MenuItem
                      icon={<FiShield size={16} />}
                      as={ChakraLink}
                      href="https://souzokushindan.com/privacy.html"
                      isExternal={true}
                    >
                      <Text mt={1.5}>プライバシーポリシー</Text>
                    </MenuItem>

                    <MenuDivider />

                    <MenuItem
                      icon={<FiLogOut size={16} />}
                      onClick={handleLogout}
                    >
                      ログアウト
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Hide>
        </HStack>

        <Show above="md">
          <Box
            w="full"
            bg="green.100"
            p={3}
          >
            <Text>
              {my?.name || "-"} 様 | 有効期限 {my?.approvalPeriod}
            </Text>
          </Box>
        </Show>

        <HStack
          w="full"
          spacing={0}
          flexGrow={1}
        >
          <Show above="md">
            <VStack
              minW={220}
              h="full"
              bg="base.900"
              color="white"
              alignItems="flex-start"
              p={3}
              spacing={5}
            >
              {!loading && !!my && (
                <>
                  <HStack
                    spacing={3}
                    justifyContent="center"
                    as={Link}
                    to="/welcome"
                  >
                    <FiHome size={16} />
                    <Text>ホーム</Text>
                  </HStack>
                  {(my?.status === 1 || my?.status === 2) && (
                    <HStack
                      spacing={3}
                      justifyContent="center"
                      as={Link}
                      to="/exam"
                    >
                      <FiCheckSquare size={16} />
                      <Text>チェックシート</Text>
                    </HStack>
                  )}
                  <HStack
                    spacing={3}
                    justifyContent="center"
                    as={Link}
                    to="/profile"
                  >
                    <FiSettings size={16} />
                    <Text>個人情報</Text>
                  </HStack>
                  {(my?.status === 2 ||
                    my?.status === 3 ||
                    my?.status === 4) && (
                    <HStack
                      spacing={3}
                      justifyContent="center"
                      as={Link}
                      to="/licenses"
                    >
                      <FiRotateCw size={16} />
                      <Text>資格更新</Text>
                    </HStack>
                  )}
                  <HStack
                    spacing={3}
                    justifyContent="center"
                    as={Link}
                    to="/e-commerce"
                  >
                    <FiShoppingCart size={16} />
                    <Text>各種通販</Text>
                  </HStack>
                  <HStack
                    spacing={3}
                    justifyContent="center"
                    as={Link}
                    to="/payments"
                  >
                    <FiCreditCard size={16} />
                    <Text>決済履歴</Text>
                  </HStack>
                  {(my?.status === 1 || my?.status === 2) && (
                    <HStack
                      spacing={3}
                      justifyContent="center"
                      as={Link}
                      to="/downloads"
                    >
                      <FiDownloadCloud size={16} />
                      <Text>各種ダウンロード</Text>
                    </HStack>
                  )}
                  <HStack
                    spacing={3}
                    justifyContent="center"
                    as={Link}
                    to="/seminars"
                  >
                    <FiCast size={16} />
                    <Text>オンライン・セミナー</Text>
                  </HStack>
                  {my?.status === 5 && (
                    <HStack
                      spacing={3}
                      justifyContent="center"
                      as={Link}
                      to="/others"
                    >
                      <FiTerminal size={16} />
                      <Text>その他</Text>
                    </HStack>
                  )}
                  <Divider />
                  <HStack
                    spacing={3}
                    justifyContent="center"
                    as={ChakraLink}
                    href="https://souzokushindan.com/"
                    isExternal={true}
                  >
                    <FiPower size={16} />
                    <Text>相続診断協会</Text>
                  </HStack>
                  <HStack
                    spacing={3}
                    justifyContent="center"
                    as={ChakraLink}
                    href="/law"
                    isExternal={true}
                  >
                    <FiPackage size={16} />
                    <Text>特定商取引法</Text>
                  </HStack>
                  <HStack
                    spacing={3}
                    justifyContent="center"
                    as={ChakraLink}
                    href="https://souzokushindan.com/privacy.html"
                    isExternal={true}
                  >
                    <FiShield size={16} />
                    <Text>プライバシーポリシー</Text>
                  </HStack>

                  <Divider />

                  <HStack
                    spacing={3}
                    justifyContent="center"
                    onClick={handleLogout}
                  >
                    <FiLogOut size={16} />
                    <Text>ログアウト</Text>
                  </HStack>
                </>
              )}
            </VStack>
          </Show>

          <VStack
            w="full"
            h="full"
            alignItems="flex-start"
            p={3}
          >
            {loading || !my ? (
              <SkeletonText
                w="full"
                noOfLines={10}
                spacing={5}
                skeletonHeight={5}
              />
            ) : (
              <>{children}</>
            )}
          </VStack>
        </HStack>

        {!loading && !!my && (
          <Hide above="md">
            <SimpleGrid
              columns={4}
              spacing={0}
              w="full"
              h="50px"
              bg="base.900"
              color="white"
            >
              <Center
                w="full"
                h="full"
                p={3}
                onClick={() => navigate("/welcome")}
              >
                <FiHome size={24} />
              </Center>

              {my.status === 1 || my.status === 2 ? (
                <>
                  <Center
                    w="full"
                    h="full"
                    p={3}
                    onClick={() => navigate("/exam")}
                  >
                    <FiCheckSquare size={24} />
                  </Center>
                </>
              ) : (
                <>
                  <Center
                    w="full"
                    h="full"
                    p={3}
                    onClick={() => navigate("/licenses")}
                  >
                    <FiCreditCard size={24} />
                  </Center>
                </>
              )}

              <Center
                w="full"
                h="full"
                p={3}
                onClick={() => navigate("/profile")}
              >
                <FiSettings size={24} />
              </Center>
              <Center
                w="full"
                h="full"
                p={3}
                onClick={() => navigate("/sitemap")}
              >
                <FiMoreHorizontal size={24} />
              </Center>
            </SimpleGrid>
          </Hide>
        )}
      </VStack>
    </VStack>
  );
};
