import { useEffect, useState } from "react";
import { FiCreditCard, FiInfo } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  FormLabel,
  Heading,
  HStack,
  Link as ChakraLink,
  List,
  ListIcon,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spacer,
  Switch,
  Text,
  UnorderedList,
  VStack
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { Response } from "interfaces/api/checks";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Checks = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [checks, setChecks] = useState(0);

  useEffect(() => {
    (async () => {
      await Fetch.get(`checks`).then((res) => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
      });
    })();
  }, []);

  return (
    <Layout
      page="オンライン確認チェック項目"
      my={response?.my}
      loading={loading}
    >
      <>
        {response?.checks &&
          response.checks.map((check, index) => (
            <Card
              w="full"
              key={index}
            >
              <CardHeader>
                <Heading size="md">{check.title}</Heading>
              </CardHeader>
              <CardBody>{parse(String(check.content))}</CardBody>
              <CardFooter w="full">
                <Center w="full">
                  <Switch
                    id={`check-${index}`}
                    colorScheme="green"
                    size="lg"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      e.target.checked
                        ? setChecks(checks + 1)
                        : setChecks(checks - 1)
                    }
                  />
                  <FormLabel
                    htmlFor={`check-${index}`}
                    mt={3}
                    ml={3}
                    color="gray.500"
                  >
                    理解しました
                  </FormLabel>
                </Center>
              </CardFooter>
            </Card>
          ))}

        <Alert
          status="info"
          my={3}
        >
          <FiInfo size={16} />
          <Text ml={3}>
            認定期間を更新するには、上記すべての項目をチェックしてください。
          </Text>
        </Alert>

        <Button
          my={3}
          w="full"
          size="lg"
          bg="green.900"
          color="white"
          colorScheme="base"
          leftIcon={<FiCreditCard size={20} />}
          isDisabled={checks !== response?.checks.length}
          onClick={() => navigate("/licenses/payment")}
        >
          支払い方法へ
        </Button>
      </>
    </Layout>
  );
};
