import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack
} from "@chakra-ui/react";
import { MEMBER_TOKEN_LOCAL_KEY } from "config/const";
import { useFormik } from "formik";
import { Request, Response } from "interfaces/api/login/index";
import { Fetch } from "utils/fetch";
import { LocalStorage } from "utils/local_stoage";
import { useToast } from "utils/toast";
import { LayoutNonMember } from "views/_layout_nonmember";
import * as yup from "yup";

const validationSchemas = yup.object({
  account: yup.number().required("認定番号を入力してください"),
  password: yup
    .string()
    .required("パスワードを入力してください")
    .min(4, "パスワードを正しく入力してください")
    .max(20, "パスワードを正しく入力してください")
});

export const Index = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(
    undefined
  );
  const { sendToast } = useToast();

  const formik = useFormik({
    initialValues: {
      account: undefined,
      password: ""
    } as Request,
    validationSchema: validationSchemas,
    onSubmit: (values: Request, { setSubmitting }) => {
      Fetch.post("login", values).then((res) => {
        setSubmitting(false);

        if (res?.error || !res.token || res.token === "") {
          sendToast({
            title: "ログイン失敗しました",
            description: "相続診断士認定番号もしくはパスワードが違います。",
            status: "error"
          });
          return false;
        }

        LocalStorage.set(MEMBER_TOKEN_LOCAL_KEY, res.token);
        navigate(`/welcome`);
        return false;
      });
    }
  });

  return (
    <LayoutNonMember>
      <>
        <Center
          w="full"
          h={20}
          bg="green.800"
          color="white"
        >
          <Text
            fontSize="xl"
            p={3}
          >
            こちらのページは「相続診断士」専用ページとなっております。
          </Text>
        </Center>

        <VStack
          mt={3}
          w="full"
        >
          <Text
            fontSize="xl"
            color="green.800"
            w="full"
          >
            特定商取引法に基づく表示
          </Text>

          <TableContainer w="full">
            <Table variant="simple">
              <TableCaption>特定商取引法に基づく表示</TableCaption>
              <Tbody>
                <Tr>
                  <Th>運営団体名</Th>
                  <Td>一般社団法人 相続診断協会</Td>
                </Tr>
                <Tr>
                  <Th>運営責任者</Th>
                  <Td>山本 次郎</Td>
                </Tr>
                <Tr>
                  <Th>郵便番号</Th>
                  <Td>103-0013</Td>
                </Tr>
                <Tr>
                  <Th>所在地</Th>
                  <Td>東京都中央区日本橋人形町2-14-3 人形町ACTビル3階</Td>
                </Tr>
                <Tr>
                  <Th>電話番号</Th>
                  <Td>03-6661-9593</Td>
                </Tr>
                <Tr>
                  <Th>公開メールアドレス</Th>
                  <Td>support@souzokushindan.com</Td>
                </Tr>
                <Tr>
                  <Th>ホームページアドレス</Th>
                  <Td>http://souzokushindan.com</Td>
                </Tr>
                <Tr>
                  <Th>営業時間</Th>
                  <Td>平日 9：00～18：00</Td>
                </Tr>
                <Tr>
                  <Th>休業日</Th>
                  <Td>土曜、日曜、祝祭日、その他（年末年始の休業日など）</Td>
                </Tr>
                <Tr>
                  <Th>販売価格・送料</Th>
                  <Td>商品毎に消費税込みの価格を表示しております。</Td>
                </Tr>
                <Tr>
                  <Th>商品代金以外の料金の説明</Th>
                  <Td>
                    別途お支払い方法により代引き手数料（330円）、振込手数料がかかります。
                  </Td>
                </Tr>
                <Tr>
                  <Th colSpan={2}>お支払方法</Th>
                </Tr>
                <Tr>
                  <Th>クレジットカード</Th>
                  <Td>VISA、MASTER、JCB、AMEX、DINERS</Td>
                </Tr>
                <Tr>
                  <Th>銀行振込</Th>
                  <Td>銀行振込手数料はご負担ください。</Td>
                </Tr>
                <Tr>
                  <Th>商品代引き</Th>
                  <Td>代引き手数料330円がかかります。</Td>
                </Tr>
                <Tr>
                  <Th>お渡し時期</Th>
                  <Td>
                    毎月15日までのお手続き完了分を当月末に発送いたします。
                  </Td>
                </Tr>
                <Tr>
                  <Th>料金の返還、返品・交換について</Th>
                  <Td>
                    料金の返還はございません。万一商品が破損・汚損していた場合、違う商品をお届けした場合は、商品到着後7日以内に弊社までご連絡頂けましたら、返品・交換を承ります。お客様のご都合による返品交換は、受付はしておりません。
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      </>
    </LayoutNonMember>
  );
};
