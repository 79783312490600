import { useEffect, useState } from "react";
import NumericLabel from "react-pretty-numbers";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Checkbox,
  CheckboxGroup,
  Divider,
  Heading,
  HStack,
  Link as ChakraLink,
  SimpleGrid,
  Spacer,
  Text
} from "@chakra-ui/react";
import {
  PAYMENT_AMOUNT,
  ROBOT_PAYMENT_BANK_URL,
  ROBOT_PAYMENT_CREDIT_URL,
  ROBOT_PAYMENT_SHOP_ID
} from "config/const";
import {
  Request,
  Response as PaymentResponse
} from "interfaces/api/licenses/payment";
import { BankRequest, CreditCardRequest } from "interfaces/api/licenses/rp";
import { Response } from "interfaces/api/profile/index";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Confirm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [state, setState] = useState<{ method?: number }>(
    location.state || { method: undefined }
  );
  const [autoRenew, setAutoRenew] = useState<boolean>(true);
  const [paymentProductCode, setPaymentProductCode] = useState("");
  const [paymentProductName, setPaymentProductName] = useState("");

  const handleSwitchRenew = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setAutoRenew(true);

      if (response?.my?.status === 2 || response?.my?.status === 3) {
        setPaymentProductCode("10002");
        setPaymentProductName("相続診断士 更新料 (自動更新あり)");
      } else {
        setPaymentProductCode("10004");
        setPaymentProductName(
          "相続診断士更新料・期間外手続費用 (自動更新あり)"
        );
      }
    } else {
      setAutoRenew(false);

      if (response?.my?.status === 2 || response?.my?.status === 3) {
        setPaymentProductCode("10001");
        setPaymentProductName("相続診断士 更新料");
      } else {
        setPaymentProductCode("10003");
        setPaymentProductName("相続診断士更新料・期間外手続費用");
      }
    }
  };

  const submitRobotPayment = async (paymentID: number) => {
    let request = undefined;

    if (state.method === 1) {
      request = {
        aid: Number(ROBOT_PAYMENT_SHOP_ID),
        cod: paymentID,
        jb: "CAPTURE",
        em: response?.my?.email,
        pn: response?.my?.tel,
        member_id: response?.my?.id,
        iid: paymentProductCode
      } as BankRequest;
    } else if (state.method === 3) {
      request = {
        pt: 1,
        aid: Number(ROBOT_PAYMENT_SHOP_ID),
        cod: paymentID,
        jb: "CAPTURE",
        sf: 0,
        em: response?.my?.email,
        pn: response?.my?.tel,
        member_id: response?.my?.id,
        inm: paymentProductName,
        iid: paymentProductCode
      } as CreditCardRequest;
    } else {
      return false;
    }

    const form = document.createElement("form");
    form.method = "POST";
    form.action =
      state.method === 3 ? ROBOT_PAYMENT_CREDIT_URL : ROBOT_PAYMENT_BANK_URL;
    form._target = "blank";

    for (const [key, value] of Object.entries(request)) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = String(value);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  };

  const handlePaymentSubmit = async () => {
    if (!response?.my || !state.method) {
      return false;
    }

    const request: Request = {
      type: state.method,
      amount: PAYMENT_AMOUNT[response.my.status],
      autoRenew: autoRenew
    };

    let paymentID = undefined;

    // まず payments レコードを作成
    await Fetch.post(`payments`, request).then((res: PaymentResponse) => {
      if (res?.error || !res.payment) {
        return false;
      }

      paymentID = res.payment.id;
    });

    if (!paymentID) {
      return false;
    }

    if (state.method === 2) {
      // 代引きの場合は、決済完了画面へ遷移
      navigate(
        `/licenses/payment/complete?ap=DELIVERY&cod=${paymentID}&rst=1&gid=1`
      );
    } else {
      // ロボットペイメントさんの決済画面へ遷移
      submitRobotPayment(paymentID);
    }
  };

  useEffect(() => {
    (async () => {
      await Fetch.get(`my`).then((res) => {
        if (res?.error) {
          return false;
        }

        setResponse(res);

        // クレカの初期値を設定
        if (state.method === 3) {
          if (res?.my?.status === 2 || res?.my?.status === 3) {
            setPaymentProductCode("10002");
            setPaymentProductName("相続診断士 更新料 (自動更新あり)");
          } else {
            setPaymentProductCode("10004");
            setPaymentProductName(
              "相続診断士更新料・期間外手続費用 (自動更新あり)"
            );
          }
        }

        // 銀行振込の初期値を設定
        if (state.method === 1) {
          if (res?.my?.status === 2 || res?.my?.status === 3) {
            setPaymentProductCode("10001");
            setPaymentProductName("相続診断士 更新料");
          } else {
            setPaymentProductCode("10003");
            setPaymentProductName("相続診断士更新料・期間外手続費用");
          }
        }
      });

      setLoading(false);
    })();
  }, []);

  return (
    <Layout
      page="お支払いの確認"
      my={response?.my}
      loading={loading}
    >
      <>
        <Card
          w="full"
          mt={2}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">お支払い方法</Heading>
            <Text as="sub">お支払い方法を確認してください。</Text>
          </CardHeader>
          <CardBody>
            {state.method && state.method === 1 && (
              <Text fontSize="lg">銀行振込</Text>
            )}
            {state.method && state.method === 2 && (
              <Text fontSize="lg">代引き</Text>
            )}
            {state.method && state.method === 3 && (
              <Text fontSize="lg">クレジットカード</Text>
            )}
          </CardBody>
        </Card>

        <Card
          w="full"
          mt={2}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">お支払い金額</Heading>
            <Text as="sub">お支払い金額を確認してください。</Text>
          </CardHeader>
          <CardBody>
            <Text
              fontSize="2xl"
              color="red.700"
              as="b"
            >
              <NumericLabel>
                {response?.my?.status ? PAYMENT_AMOUNT[response.my.status] : 0}
              </NumericLabel>{" "}
              円
            </Text>
          </CardBody>
        </Card>

        {state.method === 3 && (
          <Checkbox
            my={3}
            defaultChecked
            colorScheme="green"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleSwitchRenew(e)
            }
          >
            このクレジットカードを登録し、資格の自動更新を申し込む。
          </Checkbox>
        )}

        <SimpleGrid
          w="full"
          columns={{ base: 1, sm: 2 }}
          spacing={3}
          mt={3}
        >
          <Button
            size="lg"
            onClick={() => navigate("/licenses")}
          >
            キャンセル
          </Button>
          <Button
            size="lg"
            bg="green.900"
            color="white"
            colorScheme="base"
            isDisabled={!state.method}
            onClick={handlePaymentSubmit}
          >
            決済開始
          </Button>
        </SimpleGrid>
      </>
    </Layout>
  );
};
