import { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text,
  VStack
} from "@chakra-ui/react";
import { EXAM_CHECKS } from "config/const";
import { Response } from "interfaces/api/profile/index";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

import { Form } from "./_form";
import { Result } from "./_result";

export const Index = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState<"form" | "result">("form");
  const [onamae, setOnamae] = useState<string | undefined>(undefined);
  const [age, setAge] = useState<number | undefined>(undefined);
  const [createAt, setCreateAt] = useState<string | undefined>(undefined);
  const [checks, setChecks] = useState<number[]>([]);

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await Fetch.get(`my`).then((res) => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        if (res.my?.status !== 1 && res.my?.status !== 2) {
          navigate("/welcome");
          return false;
        }

        setResponse(res);
      });
    })();
  }, []);

  return (
    <Layout
      page="相続診断チェックシート"
      my={response?.my}
      loading={loading}
    >
      <>
        {step === "form" && (
          <Form
            setStep={setStep}
            setOnamae={setOnamae}
            setAge={setAge}
            setCreateAt={setCreateAt}
            setChecks={setChecks}
            checks={checks}
          />
        )}

        {step === "result" && (
          <Result
            onamae={onamae}
            age={age}
            createAt={createAt}
            checks={checks}
            setStep={setStep}
          />
        )}
      </>
    </Layout>
  );
};
