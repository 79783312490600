import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { MEMBER_TOKEN_LOCAL_KEY } from "config/const";
import { LocalStorage } from "utils/local_stoage";
import { Index as DownloadsIndex } from "views/downloads";
import { Index as ExamIndex } from "views/exam";
import { Index } from "views/index";
import { Index as LawIndex } from "views/law";
import { Index as LicensesIndex } from "views/licenses";
import { Checks as LicensesChecks } from "views/licenses/checks";
import { Index as LicensesPaymentIndex } from "views/licenses/payment";
import { Complete as LicensesPaymentComplete } from "views/licenses/payment/complete";
import { Confirm as LicensesPaymentConfirm } from "views/licenses/payment/confirm";
import { Index as LoginIndex } from "views/login";
import { Index as PaymentsIndex } from "views/payments";
import { Index as ProfileIndex } from "views/profile";
import { Index as SeminarsIndex } from "views/seminars";
import { Index as SitemapIndex } from "views/sitemap";
import { Index as WelcomeIndex } from "views/welcome";

const theme = extendTheme({
  colors: {
    base: {
      "50": "#f4f9f3",
      "100": "#d1e6ce",
      "200": "#a9d0a3",
      "300": "#78b56e",
      "400": "#5ba54f",
      "500": "#348f24",
      "600": "#147a03",
      "700": "#106302",
      "800": "#0e5302",
      "900": "#0a3c01"
    },
    sub: {
      "50": "#F2F2F2",
      "100": "#DBDBDB",
      "200": "#C4C4C4",
      "300": "#ADADAD",
      "400": "#969696",
      "500": "#808080",
      "600": "#666666",
      "700": "#4D4D4D",
      "800": "#333333",
      "900": "#1A1A1A"
    },
    // master: '#01007C',
    slave: "#3E4FA0",
    // sub:  '#F1F1F5',
    opposite: "white",
    master: {
      "50": "#FCE8E9",
      "100": "#F8BFC2",
      "200": "#F3969A",
      "300": "#EE6D73",
      "400": "#E9444C",
      "500": "#E41B24",
      "600": "#B7151D",
      "700": "#891016",
      "800": "#5B0B0F",
      "900": "#2E0507"
    }
  }
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={<LoginIndex />}
          />
          <Route
            path="/law"
            element={<LawIndex />}
          />

          <Route
            path="/welcome"
            element={
              <ProtectedRoute>
                <WelcomeIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfileIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sitemap"
            element={
              <ProtectedRoute>
                <SitemapIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/seminars"
            element={
              <ProtectedRoute>
                <SeminarsIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/downloads"
            element={
              <ProtectedRoute>
                <DownloadsIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/exam"
            element={
              <ProtectedRoute>
                <ExamIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/licenses"
            element={
              <ProtectedRoute>
                <LicensesIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/licenses/checks"
            element={
              <ProtectedRoute>
                <LicensesChecks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/licenses/payment"
            element={
              <ProtectedRoute>
                <LicensesPaymentIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/licenses/payment/confirm"
            element={
              <ProtectedRoute>
                <LicensesPaymentConfirm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/licenses/payment/complete"
            element={
              <ProtectedRoute>
                <LicensesPaymentComplete />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments"
            element={
              <ProtectedRoute>
                <PaymentsIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <Navigate
                to="/welcome"
                replace
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  if (localStorage.getItem(MEMBER_TOKEN_LOCAL_KEY)) {
    return children;
  }

  return <Navigate to="/login" />;
};

export default App;
