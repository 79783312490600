import { useEffect, useState } from "react";
import NumericLabel from "react-pretty-numbers";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Heading,
  HStack,
  Link as ChakraLink,
  List,
  ListIcon,
  ListItem,
  OrderedList,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Text
} from "@chakra-ui/react";
import { PAYMENT_AMOUNT, PAYMENT_METHODS } from "config/const";
import { Response } from "interfaces/api/profile/index";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Index = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [method, setMethod] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      await Fetch.get(`my`).then((res) => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
      });
    })();
  }, []);

  return (
    <Layout
      page="お支払い"
      my={response?.my}
      loading={loading}
    >
      <>
        <Card
          w="full"
          mt={2}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">お支払い方法</Heading>
            <Text as="sub">お支払い方法を選択してください。</Text>
          </CardHeader>
          <CardBody>
            <RadioGroup onChange={setMethod}>
              <List spacing={5}>
                <ListItem>
                  <Text
                    as="b"
                    fontSize="lg"
                    color={method === "3" ? "green.900" : "gray.500"}
                  >
                    クレジットカード
                  </Text>
                  <HStack
                    mt={3}
                    alignItems="center"
                  >
                    <Radio
                      colorScheme="green"
                      value="3"
                    >
                      決済画面へお進み下さい
                    </Radio>
                  </HStack>
                </ListItem>

                <Divider />

                <ListItem>
                  <Text
                    as="b"
                    fontSize="lg"
                    color={method === "1" ? "green.900" : "gray.500"}
                  >
                    銀行振込
                  </Text>
                  <HStack
                    mt={3}
                    alignItems="center"
                  >
                    <Radio
                      colorScheme="green"
                      value="1"
                    >
                      ご登録頂いているメールアドレスに振込先をご案内致します
                    </Radio>
                  </HStack>
                </ListItem>

                <Divider />

                <ListItem>
                  <Text
                    as="b"
                    fontSize="lg"
                    color={method === "2" ? "green.900" : "gray.500"}
                  >
                    代引き
                  </Text>
                  <HStack
                    mt={3}
                    alignItems="center"
                  >
                    <Radio
                      colorScheme="green"
                      value="2"
                    >
                      別途代金引換手数料330円がかかります
                    </Radio>
                  </HStack>
                </ListItem>
              </List>
            </RadioGroup>
          </CardBody>
        </Card>

        <Card
          w="full"
          mt={2}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">お支払い金額</Heading>
            <Text as="sub">お支払い金額を確認してください。</Text>
          </CardHeader>
          <CardBody>
            <Text
              fontSize="2xl"
              color="red.700"
              as="b"
            >
              <NumericLabel>
                {response?.my?.status ? PAYMENT_AMOUNT[response.my.status] : 0}
              </NumericLabel>{" "}
              円
            </Text>
          </CardBody>
        </Card>

        <SimpleGrid
          w="full"
          columns={{ base: 1, sm: 2 }}
          spacing={3}
          mt={3}
        >
          <Button
            size="lg"
            onClick={() => navigate("/licenses")}
          >
            キャンセル
          </Button>
          <Button
            size="lg"
            bg="green.900"
            color="white"
            colorScheme="base"
            isDisabled={!method}
            onClick={() =>
              navigate("/licenses/payment/confirm", {
                state: { method: Number(method) }
              })
            }
          >
            確認へ
          </Button>
        </SimpleGrid>
      </>
    </Layout>
  );
};
