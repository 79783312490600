import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Hide,
  HStack,
  Image,
  Input,
  Link,
  Show,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import { MEMBER_TOKEN_LOCAL_KEY } from "config/const";

interface Props {
  children?: React.ReactElement;
  loading?: boolean;
}

export const LayoutNonMember: React.FunctionComponent<Props> = ({
  children,
  loading = true
}: Props) => {
  return (
    <VStack
      w="full"
      h="100vh"
      spacing={0}
      alignItems="center"
    >
      <VStack
        w="full"
        h="full"
        maxW={800}
        alignItems="flex-start"
        px={3}
      >
        <HStack
          w="full"
          justifyContent="space-between"
        >
          <Show above="md">
            <RouterLink to="/">
              <Image
                src="/static/images/logo/logo_jida_pc.png"
                alt="logo"
                h={50}
              />
            </RouterLink>

            <RouterLink to="/">
              <Image
                src="/static/images/header_tel.png"
                alt="tel"
                h={50}
              />
            </RouterLink>
          </Show>
          <Hide above="md">
            <RouterLink to="/">
              <Image
                src="/static/images/logo/logo_jida_sp.png"
                alt="logo"
                h={50}
              />
            </RouterLink>
          </Hide>
        </HStack>

        {children}

        <Spacer flexGrow={1} />

        <HStack
          w="full"
          spacing={10}
          mt={5}
        >
          <RouterLink to="/law">特定商取引法に基づく表示</RouterLink>
          <Link
            href="https://souzokushindan.com/privacy.html"
            isExternal={true}
          >
            プライバシーポリシー
          </Link>
        </HStack>

        <HStack
          w="full"
          justifyContent="center"
          bg="green.800"
          color="white"
        >
          <Text p={3}>
            Copyright © 2011- 2024 SOUZOKUSHINDAN-KYOKAI All Rights Reserved.
          </Text>
        </HStack>
      </VStack>
    </VStack>
  );
};
