import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Heading,
  HStack,
  Link as ChakraLink,
  List,
  ListIcon,
  ListItem,
  OrderedList,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Text
} from "@chakra-ui/react";
import { Request, Response } from "interfaces/api/licenses/payment/complete";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Complete = () => {
  const [searchParams] = useSearchParams();
  const gid = searchParams.get("gid"); // RB 側決済番号
  const rst = searchParams.get("rst"); // 1; 成功, 2; 失敗
  const ap = searchParams.get("ap"); // BANK etc ...
  const god = searchParams.get("god");
  const cod = searchParams.get("cod"); // 協会側決済番号
  const acid = searchParams.get("acid"); // 自動課金番号

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentError, setPaymentError] = useState(false);

  useEffect(() => {
    if (!gid || !cod || !ap) {
      return;
    }

    if (rst !== "1") {
      setPaymentError(true);
      return;
    }

    (async () => {
      const request: Request = {
        gid: Number(gid)
      };

      if (acid) {
        request.acid = Number(acid);
      }

      await Fetch.post(`payments/${cod}`, request).then((res: Response) => {
        if (res?.error) {
          return false;
        }

        setResponse(res);
      });

      setLoading(false);
    })();
  }, []);

  return (
    <Layout
      page="お支払い完了"
      my={response?.my}
      loading={loading}
    >
      <>
        {response?.payment?.type === 1 && (
          <Card
            w="full"
            mt={2}
          >
            <CardHeader
              borderBottomWidth={3}
              borderColor="base.700"
            >
              <Heading size="md">銀行振込</Heading>
            </CardHeader>
            <CardBody>
              <Text my={3}>
                ご登録メールアドレスに振込先のご案内をお送り致しました。
              </Text>
              <Text my={3}>
                振込期限はございませんがなるべくお早めにお手続き下さいますようお願い申し上げます。
              </Text>
              <Text my={3}>
                メールが届かない場合はメニューの「個人情報」より登録メールアドレスを確認の上、再度銀行振り込みをご選択下さい。
              </Text>
              <Text my={3}>
                お振込を弊社で確認の上、完了メールを返信致します。
              </Text>
              <Text my={3}>
                （お客様のメールの設定により迷惑メールフォルダに飛ぶ場合が稀にございます。）
              </Text>
              <Text my={3}>入金確認に2営業日ほどかかる場合がございます。</Text>
              <Text my={3}>
                また新しい認定カードの発送時期は15日までに更新完了した方は当月末、16日以降に更新完了した方は翌月末に発送されますので予めご了承下さい。
              </Text>
              <Text my={3}>
                相続診断士オンライン更新をご利用頂きまして誠にありがとうございます。
              </Text>
            </CardBody>
          </Card>
        )}

        {response?.payment?.type === 2 && (
          <Card
            w="full"
            mt={2}
          >
            <CardHeader
              borderBottomWidth={3}
              borderColor="base.700"
            >
              <Heading size="md">代金引換</Heading>
            </CardHeader>
            <CardBody>
              <Text my={3}>代金引換の受付を完了致しました。</Text>
              <Text my={3}>代金は認定カード配送時に配達員にお支払下さい。</Text>
              <Text my={3}>
                代金引換は宅配業者の発送伝票が領収書となります。
              </Text>
              <Text my={3}>
                相続診断士オンライン更新をご利用頂きまして誠にありがとうございます。
              </Text>
            </CardBody>
          </Card>
        )}

        {response?.payment?.type === 3 && (
          <Card
            w="full"
            mt={2}
          >
            <CardHeader
              borderBottomWidth={3}
              borderColor="base.700"
            >
              <Heading size="md">クレジット決済</Heading>
            </CardHeader>
            <CardBody>
              <Text my={3}>
                相続診断士オンライン更新をご利用頂きまして誠にありがとうございます。
              </Text>
              <Text my={3}>クレジットカードにてお支払をご確認できました。</Text>
              <Text my={3}>
                ご登録のメールアドレスにも完了のご案内が送信されておりますのでご確認ください。
              </Text>
              <Text
                my={3}
                color="red"
                as="b"
              >
                ※更新書面の返送は不要です。
              </Text>
              <Text my={3}>
                新しい認定カードは15日までに更新完了した方は当月末16日以降に更新完了した方は翌月末に発送されます。
              </Text>
              <Text my={3}>ご到着まで今しばらくお待ちください。</Text>
              <Text my={3}>
                こちらを持ちまして更新のお手続きが全て完了となります。
              </Text>
              <Text my={3}>何卒宜しくお願い致します。</Text>
            </CardBody>
          </Card>
        )}

        <Card
          w="full"
          mt={2}
        >
          <CardBody>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/BDPlofI7-e8"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
          </CardBody>
        </Card>
      </>
    </Layout>
  );
};
