import { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Link,
  Show,
  SkeletonText,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { Index as Response } from "interfaces/api/welcomes/index";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Index = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await Fetch.get(`welcomes`).then((res) => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
      });
    })();
  }, []);

  return (
    <Layout
      page="ホーム"
      my={response?.my}
      loading={loading}
    >
      <>
        <Show above="md">
          <Breadcrumb>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">ホーム</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Show>

        <Card
          w="full"
          mt={3}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">相続診断協会からのお知らせ</Heading>
          </CardHeader>
          <CardBody style={{ whiteSpace: "pre-line" }}>
            {response && response.welcome && response.welcome.content
              ? parse(response.welcome.content)
              : null}
          </CardBody>
        </Card>

        <Card
          w="full"
          mt={3}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">リンク集</Heading>
          </CardHeader>
          <CardBody>
            <VStack
              spacing={3}
              alignItems="flex-start"
              w="full"
            >
              <HStack
                as={Link}
                w="full"
                h={10}
                href="http://souzokushindan.com/sys/events/event/"
              >
                <Text>セミナーイベント</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                as={Link}
                w="full"
                h={10}
                href="http://souzokushindan.com/sys/successioncheckorg/"
              >
                <Text>相続診断士会</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                as={Link}
                w="full"
                h={10}
                href="http://souzokushindan.com/sys/column_type/souzokuq_a/"
              >
                <Text>相続コラム月刊Q＆A（事例一覧）</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                as={Link}
                w="full"
                h={10}
                href="http://souzokushindan.com/sys/column_type/partners_column/"
              >
                <Text>相続コラム月刊Q＆A（パートナー事務所通信）</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                as={Link}
                w="full"
                h={10}
                href="https://www.facebook.com/souzokushindan/"
              >
                <Text>相続診断協会FACEBOOKページ</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                as={Link}
                w="full"
                h={10}
                href="https://souzokushindan.com/sys/souzokuseminar/"
              >
                <Text>各種セミナー講師依頼</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                as={Link}
                w="full"
                h={10}
                href="https://souzokushindan.com/sys/contact/"
              >
                <Text>相続のご相談、相続落語のご依頼、お問い合わせなど</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                as={Link}
                w="full"
                h={10}
                href="http://souzokushindan.com/partnersoffice.html"
              >
                <Text>パートナー事務所全国一覧</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>

              <Divider />

              <HStack
                as={Link}
                w="full"
                h={10}
                href="http://souzokushindan.com/mnews.html"
              >
                <Text>笑顔相続新聞バックナンバー</Text>
                <Spacer flexGrow={1} />
                <FiChevronRight />
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </>
    </Layout>
  );
};
