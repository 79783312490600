import { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text,
  VStack
} from "@chakra-ui/react";
import { EXAM_CHECKS } from "config/const";
import { useFormik } from "formik";
import { Request } from "interfaces/api/exam/index";
import { Fetch } from "utils/fetch";
import * as yup from "yup";

interface Props {
  setStep: React.Dispatch<React.SetStateAction<"form" | "result">>;
  setOnamae: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAge: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCreateAt: React.Dispatch<React.SetStateAction<string | undefined>>;
  setChecks: React.Dispatch<React.SetStateAction<number[]>>;
  checks: number[];
}

export const Form: React.FunctionComponent<Props> = ({
  setStep,
  setOnamae,
  setAge,
  setCreateAt,
  setChecks,
  checks = []
}: Props) => {
  const validationSchemas = yup.object({
    onamae: yup.string().required("相続診断対象者のお名前を入力してください"),
    age: yup.number().required("相続診断対象者の年齢を入力してください"),
    createAt: yup
      .string()
      .required("相続診断チェックシート作成日を入力してください")
  });

  const formik = useFormik({
    initialValues: {
      onamae: "",
      age: 50,
      createAt: new Date().toISOString().substring(0, 10)
    } as Request,
    validationSchema: validationSchemas,
    onSubmit: (values: Request) => {
      setOnamae(values.onamae);
      setAge(values.age);
      setCreateAt(values.createAt);

      setStep("result");
    }
  });

  const handleChangeCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let _checks = checks;
    if (e.target.checked) {
      _checks.push(index);
    } else {
      _checks = _checks.filter((check) => check !== index);
    }

    setChecks(_checks.sort((a, b) => a - b));
  };

  useEffect(() => {
    setChecks([]);
  }, []);

  return (
    <VStack
      spacing={5}
      w="full"
    >
      <Card
        w="full"
        mt={2}
      >
        <CardHeader>
          <Heading size="md">診断情報</Heading>
          <Text as="sub">
            下記項目に相続診断を行いたい方の情報を入力またはチェックしてください。
          </Text>
        </CardHeader>
        <CardBody>
          <VStack
            spacing={5}
            alignItems="flex-start"
            w="full"
          >
            <FormControl
              isRequired
              isInvalid={formik.touched.onamae && Boolean(formik.errors.onamae)}
            >
              <FormLabel>相続診断対象者のお名前</FormLabel>
              <Input
                id="onamae"
                name="onamae"
                type="text"
                placeholder="相続診断対象者のお名前を入力してください"
                isRequired
                onChange={formik.handleChange}
                value={formik.values.onamae}
                maxLength={50}
              />
              <FormErrorMessage>
                {formik.errors.onamae?.toString()}
              </FormErrorMessage>
            </FormControl>

            <SimpleGrid
              columns={2}
              spacing={3}
              w="full"
            >
              <FormControl
                isRequired
                isInvalid={formik.touched.age && Boolean(formik.errors.age)}
              >
                <FormLabel>相続診断対象者の年齢</FormLabel>
                <Select
                  id="age"
                  name="age"
                  placeholder="相続診断対象者の年齢を入力してください"
                  isRequired
                  onChange={formik.handleChange}
                  value={formik.values.age}
                >
                  {[...Array(80)].map((x, i) => (
                    <option
                      value={i + 20}
                      key={i + 20}
                    >
                      {i + 20}歳
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {formik.errors.age?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.age && Boolean(formik.errors.age)}
              >
                <FormLabel>相続診断チェックシート作成日</FormLabel>
                <Input
                  id="createAt"
                  name="createAt"
                  type="date"
                  placeholder="相続診断チェックシート作成日を入力してください"
                  isRequired
                  onChange={formik.handleChange}
                  value={formik.values.createAt}
                />
                <FormErrorMessage>
                  {formik.errors.createAt?.toString()}
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </VStack>
        </CardBody>
      </Card>

      <Card w="full">
        <CardHeader>
          <Heading size="md">チェック項目</Heading>
          <Text as="sub">
            対象者からお預かりしたチェックシート内容をチェックしてください。
          </Text>
        </CardHeader>
        <CardBody>
          {EXAM_CHECKS.map((check, index) => (
            <FormControl
              key={index}
              display="flex"
              alignItems="center"
              mb={3}
            >
              <HStack
                w={6}
                justifyContent="flex-end"
              >
                <Text
                  color="red.800"
                  fontSize="lg"
                  as="b"
                >
                  {index + 1}
                </Text>
              </HStack>
              <Switch
                id={`check-${index}`}
                colorScheme="green"
                ml={3}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeCheck(e, index)
                }
              />
              <FormLabel
                htmlFor={`check-${index}`}
                mt={3}
                ml={3}
              >
                {check.description}
              </FormLabel>
            </FormControl>
          ))}
        </CardBody>
      </Card>

      <SimpleGrid
        columns={2}
        spacing={3}
        w="full"
      >
        <Button
          w="full"
          size="lg"
        >
          リセット
        </Button>
        <Button
          w="full"
          bg="green.800"
          color="white"
          size="lg"
          colorScheme="base"
          onClick={() => formik.handleSubmit()}
        >
          診断する
        </Button>
      </SimpleGrid>
    </VStack>
  );
};
