import { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Link,
  Show,
  SkeletonText,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import { Index as Response } from "interfaces/api/seminars/index";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Index = () => {
  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await Fetch.get(`seminars`).then((res) => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
      });
    })();
  }, []);

  return (
    <Layout
      page="オンライン・セミナー"
      my={response?.my}
      loading={loading}
    >
      <>
        <Show above="md">
          <Breadcrumb>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">オンライン・セミナー</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Show>

        <Card
          w="full"
          mt={3}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">オンライン・セミナー一覧</Heading>
          </CardHeader>
          <CardBody>
            {loading && (
              <SkeletonText
                noOfLines={10}
                spacing={5}
                skeletonHeight={5}
              />
            )}
            {response && response.seminars && (
              <VStack
                w="full"
                spacing={3}
              >
                {response.seminars.map((seminar, index) => (
                  <Link
                    w="full"
                    key={index}
                    href={seminar.url}
                  >
                    <HStack w="full">
                      <VStack alignItems="flex-start">
                        <Text>{seminar.title}</Text>
                        <Text as="sub">{seminar.url}</Text>
                      </VStack>
                      <Spacer />
                      <FiChevronRight />
                    </HStack>

                    <Divider my={3} />
                  </Link>
                ))}
              </VStack>
            )}
          </CardBody>
        </Card>
      </>
    </Layout>
  );
};
