import { useEffect, useState } from "react";
import { FiClipboard, FiCloud } from "react-icons/fi";
import NumericLabel from "react-pretty-numbers";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Heading,
  HStack,
  Link as ChakraLink,
  List,
  ListIcon,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spacer,
  Text,
  UnorderedList,
  VStack
} from "@chakra-ui/react";
import { PAYMENT_AMOUNT } from "config/const";
import { Response } from "interfaces/api/profile/index";
import { Fetch } from "utils/fetch";
import { LayoutMember as Layout } from "views/_layout_member";

export const Index = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await Fetch.get(`my`).then((res) => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
      });
    })();
  }, []);

  return (
    <Layout
      page="資格更新"
      my={response?.my}
      loading={loading}
    >
      <>
        <Text>
          相続診断協会が相続診断士の資格認定をスタートし、現在多くの有資格者が、相続の現場で活躍されています。
        </Text>
        <Text>
          この資格が社会的にも高い評価を受けていることは大変に喜ばしいことでもありますが、同時にその責任の重さと、笑顔相続の周知により一層の努力が必要であることを痛感しております。
        </Text>
        <Text>さて相続診断士資格は2 年ごとの資格更新制度をとっています。</Text>
        <Text>
          有資格者が常に最先端の法律や事例を学ぶと同時に正しい相続診断を行い、必要に応じて提携パートナーとのネットワークに参加することで現代社会において相続に関する様々な問題に対処する実力を養い向上させることにあります。
        </Text>
        <Text>
          資格更新にあたり新たな自覚をもって、今後の活動への尽力を期待します。
        </Text>

        <Card
          w="full"
          mt={2}
        >
          <CardHeader
            borderBottomWidth={3}
            borderColor="base.700"
          >
            <Heading size="md">資格更新の手続きについて</Heading>
          </CardHeader>
          <CardBody>
            <VStack
              spacing={3}
              alignItems="flex-start"
              w="full"
            >
              <HStack
                w="full"
                alignItems="flex-start"
              >
                <Center
                  minW="40px"
                  minH="40px"
                  rounded={32}
                  color="white"
                  bg="green.800"
                  mr={3}
                >
                  1
                </Center>
                <VStack
                  w="full"
                  alignItems="flex-start"
                >
                  <Text as="b">資格更新料</Text>
                  <Text>
                    <NumericLabel>
                      {response?.my?.status
                        ? PAYMENT_AMOUNT[response.my.status]
                        : 0}
                    </NumericLabel>
                    円（税込）
                  </Text>

                  <Text
                    as="b"
                    mt={3}
                  >
                    資格有効期間の確認
                  </Text>
                  <Text>
                    協会が発行している相続診断士認定カードに記載があります。
                  </Text>

                  <Text
                    as="b"
                    mt={3}
                  >
                    資格有効期間
                  </Text>
                  <Text>
                    有効期間の3か月前より更新手続きを行うことができます。
                  </Text>
                  <Text>
                    有効期限を越えての更新については、6か月まで更新を行うことができますが、
                  </Text>
                  <Text>
                    未更新の期間が3カ月以上経過した場合には、別途更新事務手続き費用（5,500円税込）がかかります。
                  </Text>
                  <Text>ただし有効期間の延長はいたしません。</Text>

                  <Text
                    as="b"
                    mt={3}
                  >
                    更新特典
                  </Text>
                  <Text>
                    更新をされた方には「笑顔相続ノート」「笑顔相続ノート練習帳」をお送りいたします。
                  </Text>
                  <Text>（新しい資格証と一緒にお送りいたします。）</Text>
                </VStack>
              </HStack>

              <HStack
                w="full"
                alignItems="flex-start"
              >
                <Center
                  minW="40px"
                  minH="40px"
                  rounded={32}
                  color="white"
                  bg="green.800"
                  mr={3}
                >
                  2
                </Center>
                <VStack
                  w="full"
                  alignItems="flex-start"
                >
                  <Text>
                    下記より「オンライン更新チェック項目」にて内容を確認頂き、全ての項目にチェックを入れて下さい。
                  </Text>
                </VStack>
              </HStack>

              <HStack
                w="full"
                alignItems="flex-start"
              >
                <Center
                  minW="40px"
                  minH="40px"
                  rounded={32}
                  color="white"
                  bg="green.800"
                  mr={3}
                >
                  3
                </Center>
                <VStack
                  w="full"
                  alignItems="flex-start"
                >
                  <Text>
                    確認後、更新料のお支払い方法を銀行振込、代金引換、クレジットカード決済の中から選択頂けます。
                  </Text>
                  <UnorderedList
                    color="red.700"
                    spacing={3}
                  >
                    <ListItem>
                      銀行振込の場合にも口座情報をメールにて案内いたします。
                    </ListItem>
                    <ListItem>
                      代金引換の場合にもご案内メールをお送りします。
                    </ListItem>
                    <ListItem>
                      クレジットカードでの支払い
                      <Text>
                        株式会社ROBOT
                        PAYMENTの電子決済サービスを使用しています。
                      </Text>
                    </ListItem>
                    <ListItem>
                      新しい認定カードの発送時期は15日までに更新完了した方は当月末、16日以降に更新完了した方は翌月末に発送されます。
                    </ListItem>
                  </UnorderedList>
                </VStack>
              </HStack>
            </VStack>
          </CardBody>
        </Card>

        <SimpleGrid
          w="full"
          columns={{ base: 1, sm: 2 }}
          spacing={3}
          mt={3}
        >
          <Button
            size="lg"
            bg="green.900"
            color="white"
            colorScheme="base"
            leftIcon={<FiCloud size={20} />}
            onClick={() => navigate("/licenses/checks")}
          >
            オンライン更新
          </Button>
          <Button
            size="lg"
            leftIcon={<FiClipboard size={20} />}
            as={ChakraLink}
            href="https://souzokushindan.com/sys/koshin/"
            isExternal={true}
          >
            紙での更新で手続きを希望
          </Button>
        </SimpleGrid>
      </>
    </Layout>
  );
};
