import {
  API_URL,
  MEMBER_TOKEN_HEADER,
  MEMBER_TOKEN_LOCAL_KEY
} from "config/const";

import { LocalStorage } from "./local_stoage";

const requestHeaders: HeadersInit = new Headers();

export const Fetch = {
  async withToken() {
    const token = await LocalStorage.get(MEMBER_TOKEN_LOCAL_KEY);

    if (token && token !== "") {
      requestHeaders.set(MEMBER_TOKEN_HEADER, token);
    }
  },

  async get(endpoint: string) {
    await Fetch.withToken();

    return await fetch(`${API_URL}/${endpoint}`, {
      method: "GET",
      headers: requestHeaders
    })
      .then((res) => checkStatus(res))
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return {
          error: error
        };
      });
  },

  async post(endpoint: string, data: FormData | object) {
    await Fetch.withToken();

    if (data instanceof FormData === false) {
      requestHeaders.set("Content-Type", "application/json");
    }

    const body = data instanceof FormData ? data : JSON.stringify(data);
    return await fetch(`${API_URL}/${endpoint}`, {
      method: "POST",
      headers: requestHeaders,
      body: body
    })
      .then((res) => checkStatus(res))
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return {
          error: error
        };
      });
  },

  async delete(endpoint: string) {
    await Fetch.withToken();

    return await fetch(`${API_URL}/${endpoint}`, {
      method: "DELETE",
      headers: requestHeaders
    })
      .then((res) => checkStatus(res))
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return {
          error: error
        };
      });
  },

  async upload(endpoint: string, data: FormData) {
    await Fetch.withToken();

    requestHeaders.set("Content-Type", "multipart/form-data");
    requestHeaders.delete("Content-Type");

    return await fetch(`${API_URL}/${endpoint}`, {
      method: "POST",
      headers: requestHeaders,
      body: data
    })
      .then((res) => checkStatus(res))
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return {
          error: error
        };
      });
  },

  async getResponse2Base64(endpoint: string) {
    await Fetch.withToken();

    return await fetch(`${API_URL}/${endpoint}`, {
      method: "GET",
      headers: requestHeaders
    })
      .then((res) => checkStatus(res))
      .then((res) => res.blob())
      .then((res) => blobToBase64(res))
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return {
          error: error
        };
      });
  },

  async download(endpoint: string, filename: string, data: FormData | object) {
    await Fetch.withToken();

    if (data instanceof FormData === false) {
      requestHeaders.set("Content-Type", "application/json");
    }

    const body = data instanceof FormData ? data : JSON.stringify(data);

    return await fetch(`${API_URL}/${endpoint}`, {
      method: "POST",
      headers: requestHeaders,
      body: body
    })
      .then((res) => checkStatus(res))
      .then((res) => res.blob())
      .then((res) => {
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

        const url = window.URL.createObjectURL(new Blob([bom, res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        link.click();

        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        return {
          error: error
        };
      });
  }
};

const checkStatus = (res: Response) => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 401) {
      LocalStorage.set(MEMBER_TOKEN_LOCAL_KEY);
      window.location.replace("/login");
    }

    const error = res.status;
    throw error;
  }
};

const blobToBase64 = (res: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(res);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
