import { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  VStack
} from "@chakra-ui/react";
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  plugins,
  PointElement,
  RadialLinearScale,
  Tooltip
} from "chart.js";
import { EXAM_CATEGORIES, EXAM_CHECKS } from "config/const";
import { Request } from "interfaces/api/exam/download";
import { check } from "prettier";
import { Fetch } from "utils/fetch";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface Props {
  setStep: React.Dispatch<React.SetStateAction<"form" | "result">>;
  onamae: string | undefined;
  age: number | undefined;
  createAt: string | undefined;
  checks: number[];
}

export const Result: React.FunctionComponent<Props> = ({
  setStep,
  onamae,
  age,
  createAt,
  checks
}: Props) => {
  const [total, setTotal] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  const [diffcult, setDiffcult] = useState(0);
  const [danger, setDanger] = useState("E");
  const [comments, setComments] = useState<string[]>([]);

  const data = {
    labels: EXAM_CATEGORIES.map((category) => category.name),
    datasets: [
      {
        data: total,
        backgroundColor: "rgba(0, 45, 15, 0.5)",
        borderColor: "rgba(0, 63, 8, 1)",
        borderWidth: 1
      }
    ]
  };

  const handleDownload = async () => {
    const request: Request = {
      onamae: onamae,
      age: Number(age),
      createAt: createAt,
      diffcult: diffcult,
      danger: danger,
      checks: checks,
      comments: comments,
      total: total
    };
    await Fetch.download(`exam/download`, "相続診断結果.pdf", request).then(
      (res) => {
        if (res?.error) {
          return false;
        }
      }
    );
  };

  useEffect(() => {
    const _total = [0, 0, 0, 0, 0, 0];
    const _comments: string[] = [];
    let _diffcult = 0;

    checks.forEach((check) => {
      const category = EXAM_CHECKS[check].category;
      _total[category] += EXAM_CHECKS[check].score;
      _comments.push(EXAM_CHECKS[check].comment);

      if (check <= 6) {
        _diffcult += 60;
      } else {
        _diffcult += 15;
      }
    });

    if (_diffcult >= 100) {
      _diffcult = 99;
    }

    _total.forEach((t, index) => {
      _total[index] = Math.floor((t * 100) / EXAM_CATEGORIES[index].total);
    });

    setTotal(_total);
    setDiffcult(_diffcult);
    setComments(_comments);

    if (age && age <= 39) {
      setDanger("E");
    }
    if (age && age >= 40 && age <= 50) {
      setDanger("D");
    }
    if (age && age >= 51 && age <= 50) {
      setDanger("C");
    }
    if (age && age >= 61 && age <= 70) {
      setDanger("B");
    }
    if (age && age > 70) {
      setDanger("A");
    }
  }, [checks]);

  return (
    <VStack
      spacing={5}
      w="full"
    >
      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        spacing={5}
        w="full"
      >
        <VStack
          spacing={5}
          w="full"
        >
          <Card
            w="full"
            mt={2}
          >
            <CardHeader>
              <Heading size="md">危険度ランク</Heading>
              <Text as="sub">
                危険度ランクは高い得点ほど危険を表しています。
              </Text>
            </CardHeader>
            <CardBody>
              <HStack>
                <Text
                  fontSize="6xl"
                  color="red.700"
                  as="b"
                >
                  {diffcult}*
                </Text>
                <Text>点</Text>
              </HStack>
            </CardBody>
          </Card>

          <Card
            w="full"
            mt={2}
          >
            <CardHeader>
              <Heading size="md">緊急度ランク</Heading>
              <Text as="sub">
                緊急度ランクはAに近いほど緊急を表しています。
              </Text>
            </CardHeader>
            <CardBody>
              <HStack>
                <Text
                  fontSize="6xl"
                  color="red.700"
                  as="b"
                >
                  {danger}
                </Text>
                <Text>点</Text>
              </HStack>
            </CardBody>
          </Card>
        </VStack>

        <Card
          w="full"
          mt={2}
        >
          <CardBody>
            <Center>
              <Radar
                data={data}
                options={{ plugins: { legend: { display: false } } }}
              />
            </Center>
          </CardBody>
        </Card>
      </SimpleGrid>

      <Card
        w="full"
        mt={2}
      >
        <CardHeader>
          <Heading size="md">コメント</Heading>
        </CardHeader>
        <CardBody>
          {checks.map((check, index) => (
            <Box key={index}>
              <HStack
                w="full"
                mb={5}
              >
                <Center
                  minW="40px"
                  minH="40px"
                  rounded={32}
                  color="white"
                  bg="green.800"
                  mr={3}
                >
                  {index + 1}
                </Center>
                <Text>{EXAM_CHECKS[check].comment}</Text>
              </HStack>
            </Box>
          ))}
        </CardBody>
      </Card>

      <SimpleGrid
        columns={2}
        spacing={3}
        w="full"
      >
        <Button
          w="full"
          size="lg"
          onClick={() => setStep("form")}
        >
          戻る
        </Button>
        <Button
          w="full"
          bg="green.800"
          color="white"
          size="lg"
          colorScheme="base"
          onClick={handleDownload}
        >
          ダウンロード
        </Button>
      </SimpleGrid>
    </VStack>
  );
};
